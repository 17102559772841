import { observable } from 'mobx';
import adminApi from 'src/api/admin-api';
import { downloadBlob } from 'src/utils/download-blob';
import { getFetch } from 'src/utils/get-fetch';

export default class DocumentsStore {
  constructor(parent) {
    this.parent = parent;
    this.api = this.parent.api.documents;
  }

  @observable fillConfigByFormUuid = new Map();

  bulkDownload = async (transactionId, filename, tdvIds) => {
    const zipFile = await this.api.getZipFileBlob({
      transactionId,
      tdvIds,
      filename,
    });
    return downloadBlob(zipFile, filename);
  };

  fetchPspdfkitDoc = async (config) => {
    const { doc, fillableFormId, pspdfkitFetchDocOptions } = config;

    try {
      let pspdfkitDocData;
      if (fillableFormId) {
        pspdfkitDocData = await this.api.getPspdfkitDocumentByForm(
          fillableFormId
        );
      } else if (doc && doc.uuid) {
        pspdfkitDocData = await this.api.getPspdfkitDocumentByDoc(
          doc.uuid,
          pspdfkitFetchDocOptions || {}
        );
      } else if (doc && doc.url) {
        pspdfkitDocData = await this.api.getPspdfkitDocumentByUrl(
          doc.url,
          pspdfkitFetchDocOptions || {}
        );
      } else {
        // nothing to fetch
        return true;
      }

      if (
        pspdfkitDocData &&
        (pspdfkitDocData.status !== 200 || !pspdfkitDocData.data)
      ) {
        throw new Error(
          `${pspdfkitDocData.status} ${(
            pspdfkitDocData.data || ''
          ).toString()}`.trim()
        );
      }
      return pspdfkitDocData.data;
    } catch (err) {
      this.parent.ui.wentWrong(err);
      return null;
    }
  };

  fetchAnnotateData = async (transactionId, tdvId) => {
    try {
      const annotateData = await this.api.getPspdfkitEditableDocumentByTdv(
        transactionId,
        tdvId
      );
      if (annotateData && (annotateData.status !== 200 || !annotateData.data)) {
        throw new Error(
          `${annotateData.status} ${(
            annotateData.data || ''
          ).toString()}`.trim()
        );
      }
      return annotateData.data;
    } catch (err) {
      this.parent.ui.wentWrong(err);
      return null;
    }
  };

  getFetchOcrTerms = getFetch({
    bindTo: this,
    fetcher: () => adminApi.documents.getOcrTerms().then(({ data }) => data),
  });
}
